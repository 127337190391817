
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { captureMessageForSentry } from '@/helpers/sentry-helper';

import { NewPageType, ObjectType, Platform, PopularTitlesSorting } from '@/@types/graphql-types';
import {
	GetGlobalNewTitlesDocument,
	GetGlobalNewTitlesQueryVariables,
} from '@/pages/graphql/queries/GetGlobalNewTitles.query';
import {
	GetPopularTitlesDocument,
	GetPopularTitlesQueryVariables,
} from '@/pages/graphql/queries/GetPopularTitles.query';

export enum TitleType {
	Top10 = 'TOP_10',
	Upcoming = 'UPCOMING',
	New = 'NEW',
	Popular = 'POPULAR',
}

@Component({
	name: 'ProvideGlobalTitleData',
	apollo: {
		titleData: {
			fetchPolicy: 'cache-first',
			query() {
				switch (this.type) {
					case TitleType.New:
						return GetGlobalNewTitlesDocument;
					default:
						return GetPopularTitlesDocument;
				}
			},
			variables() {
				switch (this.type) {
					case TitleType.New:
						return this.newQueryVariables;
					default:
						return this.popularQueryVariables;
				}
			},
			update(data) {
				switch (this.type) {
					case TitleType.New:
						return data.newTitles?.edges;
					default:
						return data.popularTitles?.edges;
				}
			},
			errorPolicy: 'all',
			error(error) {
				captureMessageForSentry(
					'[GraphQL Get Global Title Data]:',
					{ error: error, where: 'Component: ProvideGlobalTitleData' },
					'error'
				);
			},
		},
	},
})
export default class ProvideGlobalTitleData extends Vue {
	titleData = [];

	@Prop() country: string;
	@Prop() language: string;
	@Prop() objectType: ObjectType;
	@Prop() type: TitleType;

	get popularQueryVariables(): GetPopularTitlesQueryVariables {
		return {
			first: this.type === TitleType.Popular ? 40 : 10,
			popularTitlesFilter: {
				objectTypes: [this.objectType],
				excludeIrrelevantTitles: false,
			},
			watchNowFilter: {},
			popularTitlesSortBy:
				this.type === TitleType.Popular ? PopularTitlesSorting.Popular : PopularTitlesSorting.Trending,
			language: this.language,
			country: this.country,
			platform: Platform.Web,
		};
	}

	get newQueryVariables(): GetGlobalNewTitlesQueryVariables {
		return {
			country: this.country,
			language: this.language,
			first: 40,
			filter: {
				objectTypes: [this.objectType === ObjectType.Show ? ObjectType.ShowSeason : ObjectType.Movie],
			},
			date: '2022-11-22',
			pageType: NewPageType.New,
		};
	}
}
