
import { Component, Prop, Vue } from 'vue-property-decorator';

import GlobalCard from '@/components/home/global/GlobalCard.vue';

@Component({
	name: 'GlobalCards',
	components: { GlobalCard },
	serverCacheKey: ({ country, language }) => {
		return `global-cards-${country}-${language}`;
	},
})
export default class GlobalCards extends Vue {
	@Prop() country: string;
	@Prop() language: string;

	get globalCards() {
		return [
			{
				image: `/${ASSETS_DIR}/img/home/all_in_one_place-comp.png`,
				tagline: this.$t('WEBAPP_GLOBAL_ONE_PLACE_TAG'),
				heading: this.$t('WEBAPP_GLOBAL_ONE_PLACE'),
				text: this.$t('WEBAPP_GLOBAL_ONE_PLACE_TEXT'),
			},
			{
				image: `/${ASSETS_DIR}/img/home/one_search-comp.png`,
				tagline: this.$t('WEBAPP_GLOBAL_ONE_SEARCH_TAG'),
				heading: this.$t('WEBAPP_GLOBAL_ONE_SEARCH'),
				text: this.$t('WEBAPP_GLOBAL_ONE_SEARCH_TEXT'),
			},
			{
				image: `/${ASSETS_DIR}/img/home/one_watchlist-comp.png`,
				tagline: this.$t('WEBAPP_GLOBAL_ONE_WATCHLIST_TAG'),
				heading: this.$t('WEBAPP_GLOBAL_ONE_WATCHLIST'),
				text: this.$t('WEBAPP_GLOBAL_ONE_WATCHLIST_TEXT'),
			},
		];
	}
}
