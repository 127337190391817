
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import HiddenHorizontalScrollbar from '@/components/HiddenHorizontalScrollbar.vue';
import PosterGraphql from '@/components/poster/PosterGraphql.vue';
import TitleDetailLink from '@/components/nav/links/TitleDetailLink.vue';

import { ObjectType } from '@/@types/graphql-types';
import { TitleType } from '@/components/home/global/ProvideGlobalTitleData.vue';
import { TrackingHelper } from '@/helpers/tracking/tracking-helper';

import type { SnowplowContext } from '@/helpers/tracking/providers/snowplow-contexts';

@Component({
	name: 'GlobalTitles',
	components: {
		HiddenHorizontalScrollbar,
		PosterGraphql,
		TitleDetailLink,
	},
	serverCacheKey: ({ country, language, objectType, type }) => {
		return `global-title-${type}-${country}-${language}-${objectType}`;
	},
})
export default class GlobalTitles extends Vue {
	@Prop() country: string;
	@Prop() language: string;
	@Prop() objectType: ObjectType.Movie | ObjectType.Show;
	@Prop() titles: any;
	@Prop() type: TitleType;

	get title() {
		if (this.objectType === ObjectType.Movie) {
			return {
				[TitleType.Top10]: this.$t('WEBAPP_GLOBAL_TOP_10_MOVIE'),
				[TitleType.Upcoming]: this.$t('WEBAPP_GLOBAL_UPCOMING_MOVIE'),
				[TitleType.New]: this.$t('WEBAPP_GLOBAL_NEW_MOVIE'),
				[TitleType.Popular]: this.$t('WEBAPP_GLOBAL_POPULAR_MOVIE'),
			}[this.type];
		}

		return {
			[TitleType.Top10]: this.$t('WEBAPP_GLOBAL_TOP_10_SHOW'),
			[TitleType.Upcoming]: this.$t('WEBAPP_GLOBAL_UPCOMING_SHOW'),
			[TitleType.New]: this.$t('WEBAPP_GLOBAL_NEW_SHOW'),
			[TitleType.Popular]: this.$t('WEBAPP_GLOBAL_POPULAR_SHOW'),
		}[this.type];
	}

	get text() {
		if (this.objectType === ObjectType.Movie) {
			return {
				[TitleType.Top10]: this.$t('WEBAPP_GLOBAL_TOP_10_MOVIE_TEXT'),
				[TitleType.Upcoming]: this.$t('WEBAPP_GLOBAL_UPCOMING_MOVIE_TEXT'),
				[TitleType.New]: this.$t('WEBAPP_GLOBAL_NEW_MOVIE_TEXT'),
				[TitleType.Popular]: this.$t('WEBAPP_GLOBAL_POPULAR_MOVIE_TEXT'),
			}[this.type];
		}

		return {
			[TitleType.Top10]: this.$t('WEBAPP_GLOBAL_TOP_10_SHOW_TEXT'),
			[TitleType.Upcoming]: this.$t('WEBAPP_GLOBAL_UPCOMING_SHOW_TEXT'),
			[TitleType.New]: this.$t('WEBAPP_GLOBAL_NEW_SHOW_TEXT'),
			[TitleType.Popular]: this.$t('WEBAPP_GLOBAL_POPULAR_SHOW_TEXT'),
		}[this.type];
	}

	get linkText() {
		if (this.objectType === ObjectType.Movie) {
			return {
				[TitleType.Top10]: null,
				[TitleType.Upcoming]: this.$t('WEBAPP_GLOBAL_UPCOMING_MOVIE_SEE_ALL'),
				[TitleType.New]: this.$t('WEBAPP_GLOBAL_NEW_MOVIE_SEE_ALL'),
				[TitleType.Popular]: this.$t('WEBAPP_GLOBAL_POPULAR_MOVIE_SEE_ALL'),
			}[this.type];
		}

		return {
			[TitleType.Top10]: null,
			[TitleType.Upcoming]: this.$t('WEBAPP_GLOBAL_UPCOMING_SHOW_SEE_ALL'),
			[TitleType.New]: this.$t('WEBAPP_GLOBAL_NEW_SHOW_SEE_ALL'),
			[TitleType.Popular]: this.$t('WEBAPP_GLOBAL_POPULAR_SHOW_SEE_ALL'),
		}[this.type];
	}

	get isTop10() {
		return this.type === TitleType.Top10;
	}

	get link() {
		if (this.objectType === ObjectType.Movie) {
			if (this.type === TitleType.Top10) return null;
			if (this.type === TitleType.Popular) return 'app.titles.popular.movie';
			if (this.type === TitleType.New) return 'app.titles.new.movie';
			if (this.type === TitleType.Upcoming) return 'app.titles.upcoming.movie';
		}
		if (this.objectType === ObjectType.Show) {
			if (this.type === TitleType.Top10) return null;
			if (this.type === TitleType.Popular) return 'app.titles.popular.show';
			if (this.type === TitleType.New) return 'app.titles.new.show';
			if (this.type === TitleType.Upcoming) return 'app.titles.upcoming.show';
		}
	}

	get trackingLabel() {
		if (this.objectType === ObjectType.Movie) {
			if (this.type === TitleType.Top10) return 'top_10_movies';
			if (this.type === TitleType.Popular) return 'popular_movies';
			if (this.type === TitleType.New) return 'new_movies';
			if (this.type === TitleType.Upcoming) return 'upcoming_movies';
		}
		if (this.objectType === ObjectType.Show) {
			if (this.type === TitleType.Top10) return 'top_10_shows';
			if (this.type === TitleType.Popular) return 'popular_shows';
			if (this.type === TitleType.New) return 'new_shows';
			if (this.type === TitleType.Upcoming) return 'upcoming_shows';
		}
	}

	handlePosterClick(title: any, index: number, contexts?: SnowplowContext[]) {
		const titleContext = TrackingHelper.getTitleContextGraphql(
			title.node.objectId,
			title.node.objectType,
			title?.node?.content?.seasonNumber
		);
		const additionalContexts: SnowplowContext[] = (contexts || []).concat([titleContext]);

		TrackingHelper.trackEvent(
			'userinteraction',
			{
				action: 'title_clicked',
				label: this.trackingLabel,
				value: index,
			},
			additionalContexts
		);
	}

	handleSeeAllClick() {
		TrackingHelper.trackEvent('userinteraction', {
			action: 'content_click',
			label: this.trackingLabel,
		});
	}
}
