import * as Types from '../../@types/graphql-types';

import gql from 'graphql-tag';
export type TrackSraEventMutationVariables = Types.Exact<{
	input: Types.TrackSraEventInput;
}>;

export type TrackSraEventMutation = {
	__typename?: 'Mutation';
	trackSraEvent: { __typename?: 'TrackSraEventPayload'; bidId: string };
};

export const TrackSraEventDocument = gql`
	mutation TrackSraEvent($input: TrackSraEventInput!) {
		trackSraEvent(input: $input) {
			bidId
		}
	}
`;
