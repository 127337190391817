import * as Types from '../../../@types/graphql-types';

import gql from 'graphql-tag';
import { PopularTitleGraphqlFragmentDoc } from '../fragments/PopularTitle.fragment';
import { SponsoredAdFragmentDoc } from '../fragments/SponsoredAd.fragment';
export type GetPopularTitlesQueryVariables = Types.Exact<{
	allowSponsoredRecommendations?: Types.InputMaybe<Types.SponsoredRecommendationsInput>;
	backdropProfile?: Types.InputMaybe<Types.BackdropProfile>;
	country: Types.Scalars['Country']['input'];
	first?: Types.Scalars['Int']['input'];
	format?: Types.InputMaybe<Types.ImageFormat>;
	language: Types.Scalars['Language']['input'];
	platform?: Types.Platform;
	after?: Types.InputMaybe<Types.Scalars['String']['input']>;
	popularTitlesFilter?: Types.InputMaybe<Types.TitleFilter>;
	popularTitlesSortBy?: Types.PopularTitlesSorting;
	profile?: Types.InputMaybe<Types.PosterProfile>;
	sortRandomSeed?: Types.Scalars['Int']['input'];
	watchNowFilter: Types.WatchNowOfferFilter;
	offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
	creditsRole?: Types.CreditRole;
}>;

export type GetPopularTitlesQuery = {
	__typename?: 'Query';
	popularTitles: {
		__typename: 'PopularTitlesConnection';
		totalCount: number;
		edges?: Array<{
			__typename?: 'PopularTitlesEdge';
			cursor: string;
			node:
				| {
						__typename: 'Movie';
						id: string;
						objectId: number;
						objectType: Types.ObjectType;
						freeOffersCount: number;
						seenlistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
						content: {
							__typename?: 'MovieContent';
							title: string;
							fullPath: string;
							posterUrl?: string | null;
							isReleased: boolean;
							runtime?: number | null;
							backdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
							scoring: {
								__typename?: 'Scoring';
								imdbVotes?: number | null;
								imdbScore?: number | null;
								tmdbPopularity?: number | null;
								tmdbScore?: number | null;
								tomatoMeter?: number | null;
								certifiedFresh?: boolean | null;
								jwRating?: number | null;
							};
							dailymotionClips: Array<{
								__typename?: 'Clip';
								sourceUrl: string;
								externalId: string;
								provider: Types.ClipProvider;
							}>;
							credits: Array<{ __typename?: 'Credit'; name: string; personId: number }>;
							genres: Array<{ __typename?: 'Genre'; translation: string; shortName: string }>;
						};
						likelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
						dislikelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
						watchlistEntryV2?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
						customlistEntries: Array<{ __typename?: 'TitleListEntry'; createdAt: any }>;
						watchNowOffer?: {
							__typename?: 'Offer';
							id: string;
							standardWebURL?: string | null;
							streamUrl?: string | null;
							retailPrice?: string | null;
							retailPriceValue?: number | null;
							lastChangeRetailPriceValue?: number | null;
							currency?: string | null;
							presentationType: Types.PresentationType;
							monetizationType: Types.MonetizationType;
							availableTo?: any | null;
							package: {
								__typename?: 'Package';
								id: string;
								icon: string;
								packageId: number;
								clearName: string;
								shortName: string;
								technicalName: string;
								iconWide: string;
								hasRectangularIcon: boolean;
							};
						} | null;
						offers: Array<{
							__typename?: 'Offer';
							id: string;
							presentationType: Types.PresentationType;
							monetizationType: Types.MonetizationType;
							retailPrice?: string | null;
							retailPriceValue?: number | null;
							currency?: string | null;
							lastChangeRetailPriceValue?: number | null;
							type: Types.OfferType;
							standardWebURL?: string | null;
							streamUrl?: string | null;
							elementCount?: number | null;
							availableTo?: any | null;
							subtitleLanguages: Array<any>;
							videoTechnology: Array<Types.VideoTechnology>;
							audioTechnology: Array<Types.AudioTechnology>;
							audioLanguages: Array<any>;
							deeplinkRoku?: string | null;
							package: {
								__typename?: 'Package';
								id: string;
								packageId: number;
								clearName: string;
								technicalName: string;
								icon: string;
								hasRectangularIcon: boolean;
								planOffers: Array<{
									__typename?: 'PackagePlanOffer';
									title?: string | null;
									retailPrice?: string | null;
									isTrial?: boolean | null;
									durationDays?: number | null;
									retailPriceValue?: number | null;
									children: Array<{
										__typename?: 'PackagePlanOffer';
										title?: string | null;
										retailPrice?: string | null;
										isTrial?: boolean | null;
										durationDays?: number | null;
										retailPriceValue?: number | null;
									}>;
								}>;
							};
						}>;
				  }
				| {
						__typename: 'Show';
						id: string;
						objectId: number;
						objectType: Types.ObjectType;
						freeOffersCount: number;
						tvShowTrackingEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
						seenState: { __typename?: 'ShowSeenState'; seenEpisodeCount: number; progress: number };
						content: {
							__typename?: 'ShowContent';
							title: string;
							fullPath: string;
							posterUrl?: string | null;
							isReleased: boolean;
							runtime?: number | null;
							backdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
							scoring: {
								__typename?: 'Scoring';
								imdbVotes?: number | null;
								imdbScore?: number | null;
								tmdbPopularity?: number | null;
								tmdbScore?: number | null;
								tomatoMeter?: number | null;
								certifiedFresh?: boolean | null;
								jwRating?: number | null;
							};
							dailymotionClips: Array<{
								__typename?: 'Clip';
								sourceUrl: string;
								externalId: string;
								provider: Types.ClipProvider;
							}>;
							credits: Array<{ __typename?: 'Credit'; name: string; personId: number }>;
							genres: Array<{ __typename?: 'Genre'; translation: string; shortName: string }>;
						};
						likelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
						dislikelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
						watchlistEntryV2?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
						customlistEntries: Array<{ __typename?: 'TitleListEntry'; createdAt: any }>;
						watchNowOffer?: {
							__typename?: 'Offer';
							id: string;
							standardWebURL?: string | null;
							streamUrl?: string | null;
							retailPrice?: string | null;
							retailPriceValue?: number | null;
							lastChangeRetailPriceValue?: number | null;
							currency?: string | null;
							presentationType: Types.PresentationType;
							monetizationType: Types.MonetizationType;
							availableTo?: any | null;
							package: {
								__typename?: 'Package';
								id: string;
								icon: string;
								packageId: number;
								clearName: string;
								shortName: string;
								technicalName: string;
								iconWide: string;
								hasRectangularIcon: boolean;
							};
						} | null;
						offers: Array<{
							__typename?: 'Offer';
							id: string;
							presentationType: Types.PresentationType;
							monetizationType: Types.MonetizationType;
							retailPrice?: string | null;
							retailPriceValue?: number | null;
							currency?: string | null;
							lastChangeRetailPriceValue?: number | null;
							type: Types.OfferType;
							standardWebURL?: string | null;
							streamUrl?: string | null;
							elementCount?: number | null;
							availableTo?: any | null;
							subtitleLanguages: Array<any>;
							videoTechnology: Array<Types.VideoTechnology>;
							audioTechnology: Array<Types.AudioTechnology>;
							audioLanguages: Array<any>;
							deeplinkRoku?: string | null;
							package: {
								__typename?: 'Package';
								id: string;
								packageId: number;
								clearName: string;
								technicalName: string;
								icon: string;
								hasRectangularIcon: boolean;
								planOffers: Array<{
									__typename?: 'PackagePlanOffer';
									title?: string | null;
									retailPrice?: string | null;
									isTrial?: boolean | null;
									durationDays?: number | null;
									retailPriceValue?: number | null;
									children: Array<{
										__typename?: 'PackagePlanOffer';
										title?: string | null;
										retailPrice?: string | null;
										isTrial?: boolean | null;
										durationDays?: number | null;
										retailPriceValue?: number | null;
									}>;
								}>;
							};
						}>;
				  };
		}> | null;
		pageInfo: {
			__typename?: 'PageInfo';
			startCursor: string;
			endCursor: string;
			hasPreviousPage: boolean;
			hasNextPage: boolean;
		};
		sponsoredAd?: {
			__typename?: 'SponsoredRecommendationAd';
			bidId: string;
			holdoutGroup: boolean;
			campaign?: {
				__typename?: 'SponsoredRecommendationCampaign';
				name: string;
				countdownTimer?: any | null;
				creativeType?: Types.CreativeType | null;
				disclaimerText?: string | null;
				hideDetailPageButton: boolean;
				hideImdbScore?: boolean | null;
				hideJwScore?: boolean | null;
				hideRatings: boolean;
				hideContent?: boolean | null;
				posterOverride?: string | null;
				promotionalImageUrl?: string | null;
				promotionalTitle?: string | null;
				promotionalText?: string | null;
				promotionalProviderLogo?: string | null;
				promotionalProviderWideLogo?: string | null;
				watchNowLabel?: string | null;
				backgroundImages?: Array<{
					__typename?: 'BackgroundImage';
					imageURL: string;
					size: Types.BackgroundImageSize;
				} | null> | null;
				externalTrackers: Array<{
					__typename?: 'ExternalTracker';
					type: Types.ExternalTrackerType;
					data: string;
				}>;
				promotionalVideo?: { __typename?: 'PromotionalVideo'; url: string } | null;
				watchNowOffer: {
					__typename?: 'Offer';
					id: string;
					standardWebURL?: string | null;
					streamUrl?: string | null;
					retailPrice?: string | null;
					retailPriceValue?: number | null;
					lastChangeRetailPriceValue?: number | null;
					currency?: string | null;
					presentationType: Types.PresentationType;
					monetizationType: Types.MonetizationType;
					availableTo?: any | null;
					package: {
						__typename?: 'Package';
						id: string;
						icon: string;
						packageId: number;
						clearName: string;
						shortName: string;
						technicalName: string;
						iconWide: string;
						hasRectangularIcon: boolean;
					};
				};
				nodeOverrides: Array<{
					__typename?: 'NodeOverride';
					nodeId: string;
					promotionalImageUrl?: string | null;
					watchNowOffer?: { __typename?: 'Offer'; standardWebURL?: string | null } | null;
				}>;
				node:
					| { __typename: 'Article'; nodeId: string }
					| { __typename: 'Author'; nodeId: string }
					| { __typename: 'Bundle'; nodeId: string }
					| { __typename: 'Device'; nodeId: string }
					| { __typename: 'Episode'; nodeId: string }
					| {
							__typename: 'GenericTitleList';
							id: string;
							type: Types.GenericTitleListType;
							nodeId: string;
							followedlistEntry?: {
								__typename?: 'FollowedListEntry';
								createdAt: any;
								name: string;
							} | null;
							content: {
								__typename?: 'GenericTitleListContent';
								name: string;
								visibility: Types.GenericTitleListVisibility;
							};
							titles: {
								__typename?: 'GenericTitleListConnection';
								totalCount: number;
								edges?: Array<{
									__typename?: 'GenericTitleListEdge';
									cursor: string;
									node:
										| {
												__typename?: 'Movie';
												id: string;
												objectId: number;
												objectType: Types.ObjectType;
												content: {
													__typename?: 'MovieContent';
													fullPath: string;
													posterUrl?: string | null;
													title: string;
													originalReleaseYear?: number | null;
													isReleased: boolean;
													scoring: {
														__typename?: 'Scoring';
														imdbVotes?: number | null;
														imdbScore?: number | null;
														tomatoMeter?: number | null;
														certifiedFresh?: boolean | null;
														jwRating?: number | null;
													};
												};
										  }
										| {
												__typename?: 'Season';
												id: string;
												objectId: number;
												objectType: Types.ObjectType;
												content: {
													__typename?: 'SeasonContent';
													fullPath: string;
													posterUrl?: string | null;
													title: string;
													originalReleaseYear?: number | null;
													isReleased: boolean;
													scoring: {
														__typename?: 'Scoring';
														imdbVotes?: number | null;
														imdbScore?: number | null;
														tomatoMeter?: number | null;
														certifiedFresh?: boolean | null;
														jwRating?: number | null;
													};
												};
										  }
										| {
												__typename?: 'Show';
												id: string;
												objectId: number;
												objectType: Types.ObjectType;
												content: {
													__typename?: 'ShowContent';
													fullPath: string;
													posterUrl?: string | null;
													title: string;
													originalReleaseYear?: number | null;
													isReleased: boolean;
													scoring: {
														__typename?: 'Scoring';
														imdbVotes?: number | null;
														imdbScore?: number | null;
														tomatoMeter?: number | null;
														certifiedFresh?: boolean | null;
														jwRating?: number | null;
													};
												};
										  };
								}> | null;
							};
					  }
					| { __typename: 'Genre'; nodeId: string }
					| {
							__typename: 'Movie';
							objectId: number;
							objectType: Types.ObjectType;
							nodeId: string;
							content: {
								__typename?: 'MovieContent';
								fullPath: string;
								posterUrl?: string | null;
								title: string;
								originalReleaseYear?: number | null;
								isReleased: boolean;
								scoring: {
									__typename?: 'Scoring';
									imdbScore?: number | null;
									jwRating?: number | null;
								};
								genres: Array<{ __typename?: 'Genre'; shortName: string; translation: string }>;
								externalIds: { __typename?: 'ExternalIds'; imdbId?: string | null };
								backdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
							};
							offers: Array<{
								__typename?: 'Offer';
								monetizationType: Types.MonetizationType;
								presentationType: Types.PresentationType;
								id: string;
								package: { __typename?: 'Package'; id: string; packageId: number };
							}>;
							watchlistEntryV2?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
					  }
					| { __typename: 'MultiStepSportEvent'; nodeId: string }
					| { __typename: 'Offer'; nodeId: string }
					| { __typename: 'Package'; nodeId: string }
					| { __typename: 'Page'; nodeId: string }
					| { __typename: 'Person'; nodeId: string }
					| {
							__typename: 'Season';
							objectId: number;
							objectType: Types.ObjectType;
							nodeId: string;
							content: {
								__typename?: 'SeasonContent';
								fullPath: string;
								posterUrl?: string | null;
								title: string;
								originalReleaseYear?: number | null;
								isReleased: boolean;
								seasonNumber: number;
								scoring: {
									__typename?: 'Scoring';
									imdbScore?: number | null;
									jwRating?: number | null;
								};
								genres: Array<{ __typename?: 'Genre'; shortName: string; translation: string }>;
								externalIds: { __typename?: 'ExternalIds'; imdbId?: string | null };
								backdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
							};
							offers: Array<{
								__typename?: 'Offer';
								monetizationType: Types.MonetizationType;
								presentationType: Types.PresentationType;
								id: string;
								package: { __typename?: 'Package'; id: string; packageId: number };
							}>;
							show: {
								__typename: 'Show';
								id: string;
								objectId: number;
								objectType: Types.ObjectType;
								content: { __typename?: 'ShowContent'; originalTitle: string };
								watchlistEntryV2?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
							};
					  }
					| {
							__typename: 'Show';
							objectId: number;
							objectType: Types.ObjectType;
							nodeId: string;
							content: {
								__typename?: 'ShowContent';
								fullPath: string;
								posterUrl?: string | null;
								title: string;
								originalReleaseYear?: number | null;
								isReleased: boolean;
								scoring: {
									__typename?: 'Scoring';
									imdbScore?: number | null;
									jwRating?: number | null;
								};
								genres: Array<{ __typename?: 'Genre'; shortName: string; translation: string }>;
								externalIds: { __typename?: 'ExternalIds'; imdbId?: string | null };
								backdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
							};
							offers: Array<{
								__typename?: 'Offer';
								monetizationType: Types.MonetizationType;
								presentationType: Types.PresentationType;
								id: string;
								package: { __typename?: 'Package'; id: string; packageId: number };
							}>;
							watchlistEntryV2?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
							seenState: { __typename?: 'ShowSeenState'; seenEpisodeCount: number };
					  }
					| { __typename: 'SingleStepSportEvent'; nodeId: string }
					| { __typename: 'SportCompetitionV2'; nodeId: string }
					| { __typename: 'SportCompetitorV2'; nodeId: string }
					| { __typename: 'Url'; nodeId: string };
			} | null;
		} | null;
	};
};

export type GetPopularMetaDataQueryVariables = Types.Exact<{
	fullPath: Types.Scalars['String']['input'];
}>;

export type GetPopularMetaDataQuery = {
	__typename?: 'Query';
	metaData: { __typename?: 'Url'; id: string; metaTitle?: string | null; metaDescription?: string | null };
};

export const GetPopularTitlesDocument = gql`
	query GetPopularTitles(
		$allowSponsoredRecommendations: SponsoredRecommendationsInput
		$backdropProfile: BackdropProfile
		$country: Country!
		$first: Int! = 70
		$format: ImageFormat
		$language: Language!
		$platform: Platform! = WEB
		$after: String
		$popularTitlesFilter: TitleFilter
		$popularTitlesSortBy: PopularTitlesSorting! = POPULAR
		$profile: PosterProfile
		$sortRandomSeed: Int! = 0
		$watchNowFilter: WatchNowOfferFilter!
		$offset: Int = 0
		$creditsRole: CreditRole! = DIRECTOR
	) {
		popularTitles(
			allowSponsoredRecommendations: $allowSponsoredRecommendations
			country: $country
			filter: $popularTitlesFilter
			first: $first
			sortBy: $popularTitlesSortBy
			sortRandomSeed: $sortRandomSeed
			offset: $offset
			after: $after
		) {
			__typename
			edges {
				cursor
				node {
					...PopularTitleGraphql
				}
			}
			pageInfo {
				startCursor
				endCursor
				hasPreviousPage
				hasNextPage
			}
			sponsoredAd {
				...SponsoredAd
			}
			totalCount
		}
	}
	${PopularTitleGraphqlFragmentDoc}
	${SponsoredAdFragmentDoc}
`;
export const GetPopularMetaDataDocument = gql`
	query GetPopularMetaData($fullPath: String!) {
		metaData: urlV2(fullPath: $fullPath) {
			id
			metaTitle
			metaDescription
		}
	}
`;
