export type ImageFormat = {
	type: string;
	extension: string;
};

// Generally we want to use webp.
export const webpFormat: ImageFormat = {
	type: 'image/webp',
	extension: '.webp',
} as const;
// But then Safari 13- doesn't support webp, so we have to fallback to jpeg.
// see https://caniuse.com/webp
export const jpegFormat: ImageFormat = {
	type: 'image/jpeg',
	extension: '', // resolves to jpeg
} as const;
