import * as Types from '../../../../@types/graphql-types';

import gql from 'graphql-tag';
export type TitleOfferFragment = {
	__typename?: 'Offer';
	id: string;
	presentationType: Types.PresentationType;
	monetizationType: Types.MonetizationType;
	retailPrice?: string | null;
	retailPriceValue?: number | null;
	currency?: string | null;
	lastChangeRetailPriceValue?: number | null;
	type: Types.OfferType;
	standardWebURL?: string | null;
	streamUrl?: string | null;
	elementCount?: number | null;
	availableTo?: any | null;
	subtitleLanguages: Array<any>;
	videoTechnology: Array<Types.VideoTechnology>;
	audioTechnology: Array<Types.AudioTechnology>;
	audioLanguages: Array<any>;
	deeplinkRoku?: string | null;
	package: {
		__typename?: 'Package';
		id: string;
		packageId: number;
		clearName: string;
		technicalName: string;
		icon: string;
		hasRectangularIcon: boolean;
		planOffers: Array<{
			__typename?: 'PackagePlanOffer';
			title?: string | null;
			retailPrice?: string | null;
			isTrial?: boolean | null;
			durationDays?: number | null;
			retailPriceValue?: number | null;
			children: Array<{
				__typename?: 'PackagePlanOffer';
				title?: string | null;
				retailPrice?: string | null;
				isTrial?: boolean | null;
				durationDays?: number | null;
				retailPriceValue?: number | null;
			}>;
		}>;
	};
};

export type FastOfferFragment = {
	__typename?: 'Offer';
	availableTo?: any | null;
	availableFromTime?: any | null;
	availableToTime?: any | null;
	id: string;
	presentationType: Types.PresentationType;
	monetizationType: Types.MonetizationType;
	retailPrice?: string | null;
	retailPriceValue?: number | null;
	currency?: string | null;
	lastChangeRetailPriceValue?: number | null;
	type: Types.OfferType;
	standardWebURL?: string | null;
	streamUrl?: string | null;
	elementCount?: number | null;
	subtitleLanguages: Array<any>;
	videoTechnology: Array<Types.VideoTechnology>;
	audioTechnology: Array<Types.AudioTechnology>;
	audioLanguages: Array<any>;
	deeplinkRoku?: string | null;
	package: {
		__typename?: 'Package';
		id: string;
		packageId: number;
		clearName: string;
		technicalName: string;
		icon: string;
		hasRectangularIcon: boolean;
		planOffers: Array<{
			__typename?: 'PackagePlanOffer';
			title?: string | null;
			retailPrice?: string | null;
			isTrial?: boolean | null;
			durationDays?: number | null;
			retailPriceValue?: number | null;
			children: Array<{
				__typename?: 'PackagePlanOffer';
				title?: string | null;
				retailPrice?: string | null;
				isTrial?: boolean | null;
				durationDays?: number | null;
				retailPriceValue?: number | null;
			}>;
		}>;
	};
};

export const TitleOfferFragmentDoc = gql`
	fragment TitleOffer on Offer {
		id
		presentationType
		monetizationType
		retailPrice(language: $language)
		retailPriceValue
		currency
		lastChangeRetailPriceValue
		type
		package {
			id
			packageId
			clearName
			technicalName
			icon(profile: S100)
			planOffers(country: $country, platform: WEB) {
				title
				retailPrice(language: $language)
				isTrial
				durationDays
				retailPriceValue
				children {
					title
					retailPrice(language: $language)
					isTrial
					durationDays
					retailPriceValue
				}
			}
			hasRectangularIcon(country: $country, platform: WEB)
		}
		standardWebURL
		streamUrl
		elementCount
		availableTo
		deeplinkRoku: deeplinkURL(platform: ROKU_OS)
		subtitleLanguages
		videoTechnology
		audioTechnology
		audioLanguages(language: $language)
	}
`;
export const FastOfferFragmentDoc = gql`
	fragment FastOffer on Offer {
		...TitleOffer
		availableTo
		availableFromTime
		availableToTime
	}
	${TitleOfferFragmentDoc}
`;
